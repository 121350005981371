<template>
     <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <div class="flex items-center justify-between px-6">
        <h1 class="text-3xl font-bold mt-20">Transaction Monitoring Portal</h1>
        <div>
            <div class="flex px-4 rounded mt-20 py-3 gap-6 text-sm text-blue-900 justify-center border border-blue-900">
            <template v-if="!showDatePicker">
                <span @click="selectToday">Today</span>
                <img src="@/assets/images/Caret.svg" alt="" class="cursor-pointer" @click="selectToday">
            </template>
            
            <input v-else type="date" v-model="dateData.startdatetime" @blur="toggleDatePicker && searchFunc" />
            </div>

            
        </div>
      </div>


      <div class="flex flex-wrap xl:-mx-5">
        <div class="my-5 px-10 w-1/3 py-auto">
          <div class="drop-shadow-lg bg-white py-12 items-center flex flex-col justify-center rounded-md h-28 mt-8 shadow-md">
            <p class="text-center font-semibold text-md">Total Transactions</p>
            <h3 class="text-center font-bold text-xl">
              {{ totalTransactions }}
            </h3>
          </div>
        </div>

        <div class="my-5 px-10 w-1/3 py-auto">
          <div class="drop-shadow-lg bg-green-500 bg-opacity-10 items-center flex flex-col justify-center rounded-md h-28 mt-8 shadow-md">
            <p class="text-center font-semibold text-md">
              Successful Transactions
            </p>
            <h3 class="text-center font-bold text-xl">
              {{ successfulTransactions }}
            </h3>
            <p
              class="bg-transparent text-green-600 mt-2 text-xs font-bold text-center"
              @click="
                $router.push({
                  name: 'TransactionMonitoringPortalTwo',
                })
              "
            >
              View
            </p>
          </div>
        </div>

        <div class="my-5 px-10 w-1/3 py-auto">
          <div class="drop-shadow-lg bg-red-100 py-10 items-center flex flex-col justify-center rounded-md h-28 mt-8 shadow-md">
            <p class="text-center font-semibold text-sm">Failed Transactions</p>
            <h3 class="text-center font-bold text-2xl">
              {{ failedTransactions }}
            </h3>
            <p
              class="bg-transparent text-red-600 mt-2 text-xs font-bold text-center cursor-pointer"
              @click="
                $router.push({
                  name: 'TransactionMonitoringPortalFailedByRoute',
                })
              "
            >
              View
            </p>
          </div>
        </div>
      </div>
      <div class="mb-5 flex mt-20 px-5">
        <div class="text-xl font-semibold">Successful Transactions</div>
        <hr>
         <div class="ml-auto">
      </div>

        <div class="float-right flex flex-row-reverse">
          <!-- filter btns  -->
          <button
            @click="filterToggle = !filterToggle"
            :class="
              filterToggle
                ? 'bg-blue-50 text-white'
                : 'bg-white text-blue-900'
            "
            class="
            ring-1
                    inline-block
                    shadow
                    border-blue-900
                    ml-2
                    text-xs
                    font-medium
                    bg-white
                    text-blue-900
                    hover:bg-blue-50
                    px-3
                    py-1
                    h-12
                    rounded
                    float"
          >
            Filter By Date
          </button>
          <button
            @click="searchByToggle = !searchByToggle"
            :class="
              searchByToggle
                ? 'bg-blue-50 text-white'
                : 'bg-white text-blue-900'
            "
            class="
            ring-1
                    inline-block
                    shadow
                    border-blue-900
                    ml-2
                    text-xs
                    font-medium
                    bg-white
                    text-blue-900
                    hover:bg-blue-50
                    px-3
                    py-1
                    h-12
                    rounded
                    float"
          >
            Filter by Time
          </button>
          <!-- search input  -->
          <div class="float-right relative">
            <input
              type="text"
              class="inline-block mx-2 px-2 border pl-12 outline-none  rounded-lg ml-2 w-64 text-sm py-3"
              name=""
              placeholder="Search by bank, route"
              id=""
              v-model="searchQuery"
            />
            <img src="@/assets/images/Vector.svg" alt="" class="cursor-pointer absolute left-8 top-4 w-3.5" >
        </div>
        </div>
      </div>
      <div class="my-4 flex bg-gray-100 p-5" v-if="searchByToggle">
        <div class="block">
          <label class="text-xs block font-bold">Time (From)</label>
          <input
            type="time"
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2"
            name=""
            id=""
            placeholder="Date"
            v-model="dateData.starttime"
          />
        </div>
        <div class="block">
          <label class="text-xs block font-bold">Time (To)</label>
          <input
            type="time"
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2"
            name=""
            id=""
            placeholder="Date"
            v-model="dateData.endtime"
          />
        </div>
        <div class="block">
          <button
            class="inline-block drop-shadow-2xl border-2
          bg-blue-900 text-white py-2 px-3 mt-4 rounded-md mr-2"
            @click="searchFunc"
          >
            Search
          </button>
        </div>
      </div>
      <div class="my-4 flex bg-gray-100 p-5" v-if="filterToggle">
        <div class="block">
          <label class="text-xs block font-bold">Date</label>
          <input
            type="date"
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2"
            name=""
            id=""
            placeholder="Date"
            v-model="dateData.startdatetime"
          />
        </div>

        <div class="block">
          <label class="text-xs block font-bold">end date</label>
          <input
            type="date"
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2"
            name=""
            id=""
            placeholder="Date"
            v-model="dateData.enddatetime"
          />
        </div>

        <div class="block">
          <button
            class="inline-block drop-shadow-2xl border-2
          bg-blue-900 text-white py-2 px-3 mt-4 rounded-md mr-2"
            @click="fetchDatedRecords"
          >
            Search
          </button>
        </div>
      </div>
      <!-- bank table -->
      <div class="mt-5 px-5" style="height: 500px; overflow: auto;">
        <table class="shadow-lg bg-white min-w-full relative border-collapse table-fixed">
          <thead>
            <tr>
              <th
                class="bg-blue-100 text-black text-sm whitespace-nowrap text-left px-8 py-5 rounded-tl-lg"
              >
                Bank
              </th>
              <!-- Display routes -->
              <th
                class="bg-blue-100 text-black text-sm whitespace-nowrap text-left px-2 py-5" 
                v-for="route in routeHeaders" :key="route">
                <span class="dark-box-button rounded-lg hover:drop-shadow-lg py-2 md:px-4 cursor-pointer">{{ formatRouteName(route) }}</span>
              </th>
            </tr>
            <!-- Display card types  -->
            <tr class="h-12 text-xs">
              <th></th>
              <th v-for="route in routeHeaders" :key="route + '-card-types'">
                <table class="min-w-full">
                  <thead>
                    <tr>
                      <th v-for="cardType in cardTypes" class="tracking-wide text-left w-20" :key="cardType">{{ getCardTypeDisplayName(cardType) }}</th>
                    </tr>
                  </thead>
                </table>
              </th>
            </tr>
          </thead>
          <tbody v-if="filteredTransactions.length">
              <!-- Loop through bank transactions -->

            <tr v-for="transaction in filteredTransactions" :key="transaction.bankName" class="border-white border border-r-0 border-l-0">
              <!-- Display bank names -->
              <td class="py-4 pl-3 w-48 bg-blue-100 bg-opacity-70 text-xs font-semibold">
                <span class="small-box-button rounded-lg cursor-pointer py-2 px-4 "> {{ formatBankName(transaction.bankName) }}</span>
              </td>
              <!-- actual table -->
              <td v-for="route in routeHeaders" :key="route + transaction.bankName" class="bg-blue-100 bg-opacity-70">
                <table class="min-w-full divide-y divide-gray-200">
                  <tbody>
                    <tr> 
                      <td v-for="(cardType, index) in cardTypes" :key="cardType + route + transaction.bankName"
                      class="text-center px-4 py-2 whitespace-nowrap font-semibold"
                      :class="{ 'border-r-2 border-white': index === cardTypes.length - 1 }">
                        <div
                          class="text-xs whitespace-nowrap text-left py-2 font-semibold w-12"
                          :class="{
                            'text-green-500': getSuccessRate(transaction, route, cardType) > 79,
                            'text-red-500': getSuccessRate(transaction, route, cardType) < 31,
                            'text-orange-700': getSuccessRate(transaction, route, cardType) >= 31 && getSuccessRate(transaction, route, cardType) <= 79
                          }"
                          @click="logSuccessRate(transaction, route, cardType)"
                        >
                          {{ Math.floor(getSuccessRate(transaction, route, cardType)) + '%'}}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
      moment,
      filterToggle: false,
      searchByToggle: false,
      totalTransactions: 0,
      successfulTransactions: 0,
      failedTransactions: 0,
      allTransactions: null,
      showDatePicker: false,
      dateData: null,
      cardTypes: ['Master', 'Visa', 'Verve'],
      routeHeaders: ['NIBSS', 'KIMONO', 'ISW'],
      searchQuery: "",
      error: null
    };
  },
    async created() {
      this.isLoading = true;

      try {
          const response = await this.$store.dispatch("FETCH_TRANSACTIONS");
          if (response.status) {
            this.allTransactions = response.data.result;
            console.log('ALL TRANSACTIONS', this.allTransactions);
            
          } else {
              console.error(response.data.status, response.data.message);
          }
     this.refreshPage();
          
      } catch (error) {
          console.error('Error fetching transactions', error);
      }
      this.isLoading = false;

      const d = new Date();

      this.dateData = {
        startdatetime: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
        enddatetime: new Date().toJSON().split("T")[0],
        starttime: this.moment(d).subtract(30, 'minutes').format("HH:mm:ss"),
        endtime: this.moment(d).format("HH:mm:ss"),
      };
},

    computed: {
    bankNames() {
      return this.allTransactions.map(transaction => this.formatBankName(transaction.bankName));
    },
    formattedCardTypes() {
      return this.cardTypes.map(cardType => {
        // Format "MasterCard" as "Mastercard"
        if (cardType === "Master") {
          return "Mastercard";
        } 
        // Format "VisaCard" and "VerveCard" by removing the last 4 characters
        else if (cardType.endsWith("Card")) {
          return cardType.slice(0, -4);
        }
        // Return unchanged if not matching any condition
        else {
          return cardType;
        }
      });
    },
    filteredTransactions() {
    const query = this.searchQuery.toLowerCase();
    const filtered = this.allTransactions.filter(transaction =>
      transaction.bankName.toLowerCase().includes(query)
    ); 
    return filtered;

  },
    ...mapGetters(['GET_TRANSACTIONS', 'GET_ERROR'])
  },
  watch: {
    allTransactions: {
      handler() {
        // Trigger filtering whenever allTransactions changes
        this.$nextTick(() => {
          this.filteredTransactions;
        });
      },
      immediate: true // Run the handler immediately after created() is executed
    }
  },
  methods: {
    ...mapActions(["FETCH_TRANSACTIONS"]),
    async searchFunc() {
      this.isLoading = true;
      try {
        const params = {
        startDate: this.dateData.startdatetime ? this.dateData.startdatetime : null,
        endDate: this.dateData.enddatetime ? this.dateData.enddatetime : null,
        startTime: this.dateData.starttime ? this.dateData.starttime : '00:00:00',
        endTime: this.dateData.endtime ? this.dateData.endtime : '23:59:59'
      };
        
        const response = await this.$store.dispatch("FETCH_TRANSACTIONS", params);

        if (response.status) {
          this.allTransactions = response.data.result;
          this.filterTransactions();
        } else {
          console.error(response.data.status, response.data.message);
        }
      } catch (error) {
        console.error('Error fetching transactions', error);
      }
      this.isLoading = false;
    },
    filterTransactions() {
      // Implement your filtering logic based on date and time here
      const { startdatetime, enddatetime, starttime, endtime } = this.dateData;
      
      this.filteredTransactions = this.allTransactions.filter(transaction => {
        const transactionDate = moment(transaction.date);
        const transactionTime = moment(transaction.time, 'HH:mm:ss');

        const isDateInRange = startdatetime ? transactionDate.isSameOrAfter(startdatetime) : true && enddatetime ? transactionDate.isSameOrBefore(enddatetime) : true;
        const isTimeInRange = starttime ? transactionTime.isSameOrAfter(moment(starttime, 'HH:mm:ss')) : true && endtime ? transactionTime.isSameOrBefore(moment(endtime, 'HH:mm:ss')) : true;

        return isDateInRange && isTimeInRange;
      });
    },
    fetchDatedRecords() {
      this.searchFunc();
    },
    formatBankName(bankName) {
      // Check if bankName already ends with " Bank"
      if (bankName.endsWith( "BANK")) {
        const words = bankName.split('_');
        const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        return capitalizedWords.join(' ');
      } else {
        const words = bankName.split('_');
        const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        return capitalizedWords.join(' ') + ' Bank';
      }
    },
    getSuccessRate(transaction, route, cardType) {
      const cardTypeKey = `${cardType.toLowerCase()}CardSuccessRate`;
      return transaction.routes[route][cardTypeKey];
    },
    logSuccessRate(transaction, route, cardType) {
      const successRate = Math.floor(this.getSuccessRate(transaction, route, cardType));
      console.log(`${cardType} Success Rate for ${transaction.bankName} on ${route}:`, successRate);
    },
    refreshPage(interval = 30 * 60 * 1000) { // Default interval: 30 minutes
      function reloadPage() {
        window.location.reload();
      }

      setInterval(reloadPage, interval);
    },
    
    selectToday() {
        this.showDatePicker = true
    },
    formatRouteName(route) {
      if (route === 'KIMONO') {
        return route.charAt(0) + route.slice(1).toLowerCase() + ' Route';
      }
      return route.charAt(0).toUpperCase() + route.slice(1) + ' Route';
    },
    getCardTypeDisplayName(cardType) {
      const cardTypeMapping = {
        Master: 'Mastercard',
        Visa: 'Visa',
        Verve: 'Verve'
      };
      return cardTypeMapping[cardType] || cardType;
    },
    toggleDatePicker() {
    this.showDatePicker = false;
    console.log('DATE HERE');
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-box-button {
    border: none;
    transition: box-shadow 0.2s ease;
  }
.dark-box-button:hover {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    color: #01388F;
  }
.small-box-button {
    border: none;
    transition: box-shadow 0.2s ease;
  }
.small-box-button:hover {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
    color: #01388F;
    background: #DEE6F2;
  }
</style>